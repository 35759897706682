module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-dc0c279bb3/0/cache/gatsby-plugin-canonical-urls-npm-4.25.0-a8d9ae213d-554a0afcc8.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"http://www.impec.com"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-theme-i18n-virtual-25ef5d385e/0/cache/gatsby-theme-i18n-npm-3.0.0-a314b01511-d4e097e876.zip/node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"ko","configPath":"D:\\impec-website\\i18n\\config.json"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-daa08f1fb7/0/cache/gatsby-plugin-manifest-npm-4.25.0-25ef1b75df-a9c755d0e5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6c76c2c20ca0ba56b07ae240976052f5"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-theme-i18n-react-intl-virtual-13a5197cae/0/cache/gatsby-theme-i18n-react-intl-npm-3.0.0-144d8cf869-9580bf44f2.zip/node_modules/gatsby-theme-i18n-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"./i18n/ko.json"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-next-seo-virtual-3277ed0ca0/0/cache/gatsby-plugin-next-seo-npm-1.10.0-e59979a254-ed02f47b12.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-a2437d3847/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
